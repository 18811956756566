<template>
  <div class="body-container">
    <div class="form-container">
      <form @submit.prevent="submitForm">
        <input 
          type="text" 
          v-model="inputID" 
          placeholder="Enter StarTram ID here" 
          class="large-input"
        />
        <button type="submit" class="submit-button" :disabled="isLoading">
          <span v-if="isLoading">Retrieving...</span>
          <span v-else>Submit</span>
        </button>
      </form>
    </div>
    <div class="subtitle">
      Enter your StarTram subscriber ID above, or use the email you used to purchase your device.
    </div>
    <div v-if="error" class="error-message">{{ error }}</div>
  </div>
</template>

<script>
import axios from 'axios';
import { inject, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  setup() {
    const inputID = ref('');
    const isLoading = ref(false);
    const error = ref('');
    const state = inject('state');
    const router = useRouter();
    const route = useRoute();

    route.query.urlid ? inputID.value = route.query.urlid : '';

    const submitForm = async () => {
      isLoading.value = true;
      error.value = '';

      try {
        const response = await axios.get(`/cx/record/${inputID.value}`);
        state.customerData = response.data;
        state.urlid = inputID.value;
        router.push({ name: 'SubDetails' });
      } catch (error) {
        console.error('Error fetching customer data:', error);
        error.value = 'Failed to fetch customer data. Please try again.';
      } finally {
        isLoading.value = false;
      }
    }

    return { inputID, submitForm, isLoading, error };
  },
};
</script>

<style scoped>
.body-container {
    height: 100%;
    background-color: #f0f0f0;
}

.body-container a {
    color: #333;
}

.form-container {
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: center;
    align-items: top;
    background-color: #f0f0f0;
}

.large-input {
    font-size: 24pt;
    border: 1px solid #333;
    font-family: 'Nunito', sans-serif;
    border-radius: 10px;
    padding: 10px 20px;
    margin-right: 10px;
    color: #333;
    background-color: #fff;
}

.submit-button {
    font-size: 24pt;
    font-family: 'Nunito', sans-serif;
    border-radius: 10px;
    border: 1px solid #333;
    padding: 10px 20px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #ddd;
}

.subtitle {
    padding: 120px;
    font-family: 'Nunito', sans-serif;
    font-size: 20pt;
}

.error-message {
  color: #a32323;
  margin-top: 20px;
  text-align: center;
}
</style>
